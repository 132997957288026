import {
  Amplify,
  Auth,
  API,
  Hub,
  Storage,
  // graphqlOperation,
} from 'aws-amplify';
import { navigateToUrl } from 'single-spa';

import { upgradeApi } from './limitless-appsync';

import awsmobile from './aws-exports';

import * as User from './user/User';
import * as ConflictHelper from './event/ConflictHelper';

import * as customQueries from './customQueries';

import appState from './appState';

Amplify.configure(awsmobile);
Auth.configure(awsmobile);
API.configure(awsmobile);
Storage.configure(awsmobile);

const getS3ObjectUrl = (key) => {
  const innerKey = `public/${key}`;
  return `https://${awsmobile.aws_user_files_s3_bucket}.s3-${awsmobile.aws_project_region}.amazonaws.com/${innerKey}`;
};

const getKeyFromS3ObjectUrl = (url) => {
  const splitted = url.split('public/');
  return splitted[1];
};

const signOutFromWebsite = async (eventId) => {
  await Auth.signOut();
  appState.user.next(null);
  navigateToUrl(`/events/${eventId}/login`);
};

export const getCognitoUser = () =>
  new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser({ bypassCache: true })
      .then(resolve)
      .catch((err) => {
        console.log(err);
        resolve(null);
      });
  });

export const getAwsUser = () =>
  new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser({ bypassCache: true })
      .then(async (user) => {
        resolve({
          ...user,
          groups:
            user?.signInUserSession?.accessToken?.payload['cognito:groups'],
        });
      })
      .catch((err) => {
        console.log(err);
        resolve(null);
      });
  });

export const getUserByMail = async (email) => {
  const res = await upgradeApi(API).graphql({
    query: customQueries.getUsersByMail,
    variables: { email: email },
  });
  if (res.data.listUsers.items.length > 0) return res.data.listUsers.items[0];
  else return undefined;
};

export const getParticipationsByMail = async (email) => {
  const res = await upgradeApi(API).graphql({
    query: customQueries.getParticipationsByMail,
    variables: { email: email },
  });
  if (res.data.listParticipations.items.length > 0)
    return res.data.listParticipations.items[0];
  else return undefined;
};

export const getUserByCognitoUserId = async (cognitoUserId) => {
  try {
    if (!cognitoUserId) {
      return null;
    } else {
      const res = await upgradeApi(API).graphql({
        query: customQueries.getUsersByCognitoUserId,
        variables: { cognitoUserId },
      });

      return res.data.usersByCognitoId.items.length
        ? res.data.usersByCognitoId.items[0]
        : null;
    }
  } catch (error) {
    console.log('getUserByCognitoUserId - error query : ', error);
  }
};

export const getDbUser = (appName) =>
  new Promise((resolve, reject) => {
    getAwsUser().then((loggedUser) => {
      if (!loggedUser?.attributes?.sub) {
        reject('no logged user');
      }
      getUserByCognitoUserId(loggedUser?.attributes?.sub).then((user) => {
        const storageUserString = localStorage.getItem(
          `impersonate-user-${appName}`
        );
        if (storageUserString) {
          appState.user.next({
            ...JSON.parse(storageUserString),
            originalUser: user,
            awsUser: loggedUser,
          });
        } else {
          appState.user.next({
            ...user,
            awsUser: loggedUser,
          });
        }
        resolve({
          ...user,
          awsUser: loggedUser,
        });
      });
    });
  });

export const signOut = async () => {
  try {
    await Auth.signOut();
    localStorage.removeItem('impersonate-user');
    appState.user.next(null);
  } catch (error) {
    console.log('error signing out: ', error);
  }
};

const createCognitoUser = async ({ username, email, userType, eventId }) =>
  await user.createNewUserInCognito({
    username,
    email,
    userType,
    eventId,
  });

const resetUserTemporaryPassword = async ({ username }) =>
  await user.resendTemporaryPassword({
    username,
  });

export const createNewUser = async (input, temporaryPassword) => {
  const cognitoUser = await createCognitoUser({
    username: input.username,
    email: input.email,
    temporaryPassword,
    userType: input.cognitoGrupName,
  });
  const sub = cognitoUser.Attributes.find((x) => x.Name === 'sub');

  await createNewUserInDb({
    cognitoUserId: sub.Value,
    email: input.email,
    givenName: input.givenName,
    familyName: input.familyName,
    type: input.type,
  });
};

export const adminUpdateCognitoUserAttributes = async (
  username,
  attributes = []
) => {
  const result = await API.post(
    'aimlambdaproxy',
    '/admin-api/adminUpdateUserAttributes',
    {
      body: {
        username,
        attributes,
      },
    }
  );

  return result;
};

export const createNewUserInDb = async (input) => {
  const { data } = await upgradeApi(API).graphql({
    query: customQueries.createUser,
    variables: { input },
  });
  return data.createUser;
};

export const getCognitoUserByUsername = async (username) =>
  await API.post('aimlambdaproxy', '/admin-api/adminGetUser', {
    body: {
      username,
    },
  }).catch(() => {
    throw new Error('user not found');
  });

export const updateUser = async (user) => {
  const { data } = await upgradeApi(API).graphql({
    query: customQueries.updateUser,
    variables: { input: user },
  });
  return data.updateUser;
};

export const adminDeleteCognitoUser = async (username) =>
  await API.post('aimlambdaproxy', '/admin-api/adminDeleteUser', {
    body: {
      username,
    },
  });

const encryptPassword = async (password) =>
  await API.post('aimlambdaproxy', '/admin-api/KMSEncrypt', {
    body: {
      password,
    },
  });

const sendMail = async (params) => {
  const apiName = 'apiSendMail';
  const path = '/sendMail';

  return await API.post(apiName, path, params);
};

const user = User.init(API);

export default {
  API: upgradeApi(API),
  standardAPI: API,
  Auth,
  Hub,
  Storage,
  s3: {
    // putObject,
    getS3ObjectUrl,
    getKeyFromS3ObjectUrl,
  },
  ConflictHelper,
  sendMail,
  getUserByMail,
  getParticipationsByMail,
  signOutFromWebsite,
  getCognitoUser,
  getAwsUser,
  getUserByCognitoUserId,
  getDbUser,
  signOut,
  createCognitoUser,
  createNewUser,
  resetUserTemporaryPassword,
  getCognitoUserByUsername,
  adminUpdateCognitoUserAttributes,
  updateUser,
  adminDeleteCognitoUser,
  encryptPassword,
};
