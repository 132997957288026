import QRCode from 'qrcode';
import bwipjs from 'bwip-js';

import { constants } from '@aim/common';

const generateQR = async (data) => {
  try {
    const QR = await QRCode.toDataURL(data, { type: 'image/png' });
    return QR;
  } catch (err) {
    console.error('QR Code generation error:', err);
  }
};

const generateQrString = async (data) => {
  try {
    const QR = await QRCode.toString(data, {
      errorCorrectionLevel: 'H',
      type: 'svg',
    });
    return QR;
  } catch (err) {
    console.error('QR Code generation error:', err);
  }
};

const generateBarcode = (
  data,
  rotation = constants.BadgesBarcodeRotation.NORMAL
) => {
  let canvas = document.createElement('canvas');
  try {
    bwipjs.toCanvas(canvas, {
      bcid: 'code128', // Barcode type
      text: data, // Text to encode
      height: 20, // Bar height, in millimeters
      includetext: false, // Show human-readable text
      textxalign: 'center',
      rotate: rotation,
    });
    return canvas.toDataURL('image/png');
  } catch (err) {
    console.error('Barcode generation error:', err);
  }
};

export default { generateQR, generateBarcode, generateQrString };
