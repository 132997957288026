const links = {
  eventWall: {
    key: 'eventWall',
    path: ({ eventId }) => `/events/${eventId}`,
    label: (intl) =>
      intl.formatMessage({
        description: 'Event Wall',
        defaultMessage: 'Event Wall',
      }),
    links: [],
  },
  genericOneLevelRoute: {
    key: 'genericOneLevelRoute',
    // path: ({eventId}) => ``, //NOT USED AS LINK
    // label: (intl) => '',
    links: ['eventWall'],
  },
  quote: {
    key: 'quote',
    path: ({ eventId }) => `/events/${eventId}/participation/quote`,
    label: (intl) =>
      intl.formatMessage({
        description: 'Fee Configuration',
        defaultMessage: 'Fee Configuration',
      }),
    links: ['eventWall'],
  },
  profiles: {
    key: 'profiles',
    path: ({ eventId }) => `/events/${eventId}/participation/profiles`,
    label: (intl) =>
      intl.formatMessage({
        description: 'Profiles',
        defaultMessage: 'Profiles',
      }),
    links: ['eventWall'],
  },
  profileQuoteAndCategories: {
    key: 'profileQuoteAndCategories',
    // path: ({eventId}) => ``, //NOT USED AS LINK
    // label: (intl) => '',
    links: ['eventWall', 'profiles'],
  },
  gateways: {
    key: 'gateways',
    path: ({ eventId }) => `/events/${eventId}/gateway`,
    label: (intl) =>
      intl.formatMessage({
        description: 'Gateways',
        defaultMessage: 'Gateways',
      }),
    links: ['eventWall'],
  },
  shops: {
    key: 'shops',
    // path: ({eventId}) => ``, //NOT USED AS LINK
    // label: (intl) => '',
    links: ['eventWall', 'gateways'],
  },
  registrationForm: {
    key: 'registrationForm',
    path: ({ eventId }) => `/events/${eventId}/participation/registration-form`,
    label: (intl) =>
      intl.formatMessage({
        description: 'Registration Form',
        defaultMessage: 'Registration Form',
      }),
    links: ['eventWall'],
  },
  registrationFormBlock: {
    key: 'registrationFormBlock',
    // path: ({ eventId }) => ``, //NOT USED AS LINK
    // label: (intl) => '',
    links: ['eventWall', 'registrationForm'],
  },
  platformInterfaceConfiguration: {
    key: 'platformInterfaceConfiguration',
    path: ({ eventId }) => `/events/${eventId}/event-configuration`,
    label: (intl) =>
      intl.formatMessage({
        description: 'Platform Interface Configuration',
        defaultMessage: 'Platform Interface Configuration',
      }),
    links: ['eventWall'],
  },
  platformInterfaceConfigurationSubRoute: {
    key: 'platformInterfaceConfigurationSubRoute',
    // path: ({ eventId }) => ``, //NOT USED AS LINK
    // label: (intl) => '',
    links: ['eventWall', 'platformInterfaceConfiguration'],
  },
  platformGeneralSettings: {
    key: 'platformGeneralSettings',
    path: ({ eventId, eventConfigurationId }) =>
      `/events/${eventId}/event-configuration/${eventConfigurationId}/general-settings`,
    label: (intl) =>
      intl.formatMessage({
        description: 'General Settings',
        defaultMessage: 'General Settings',
      }),
    links: ['eventWall', 'platformInterfaceConfiguration'],
  },
  platformGeneralSettingsSubRoute: {
    key: 'platformGeneralSettingsSubRoute',
    // path: ({ eventId }) => ``, //NOT USED AS LINK
    // label: (intl) => '',
    links: [
      'eventWall',
      'platformInterfaceConfiguration',
      'platformGeneralSettings',
    ],
  },
  platformGeneralSettingsHome: {
    key: 'platformGeneralSettingsHome',
    path: ({ eventId, eventConfigurationId }) =>
      `/events/${eventId}/event-configuration/${eventConfigurationId}/general-settings/homepage`,
    label: (intl) =>
      intl.formatMessage({
        description: 'Homepage',
        defaultMessage: 'Homepage',
      }),
    links: [
      'eventWall',
      'platformInterfaceConfiguration',
      'platformGeneralSettings',
    ],
  },
  platformGeneralSettingsHomeSubRoute: {
    key: 'platformGeneralSettingsHomeSubRoute',
    // path: ({ eventId }) => ``, //NOT USED AS LINK
    // label: (intl) => '',
    links: [
      'eventWall',
      'platformInterfaceConfiguration',
      'platformGeneralSettings',
      'platformGeneralSettingsHome',
    ],
  },
  agency: {
    key: 'agency',
    path: ({ eventId }) => `/events/${eventId}/agency`,
    label: (intl) =>
      intl.formatMessage({
        description: 'Agencies',
        defaultMessage: 'Agencies',
      }),
    links: ['eventWall'],
  },
  agencyWall: {
    key: 'agencyWall',
    path: ({ eventId }) => `/events/${eventId}/agency/wall`,
    label: (intl) =>
      intl.formatMessage({
        description: 'Wall agencies',
        defaultMessage: 'Wall agencies',
      }),
    links: ['eventWall', 'agency'],
  },
  agencyWallSubRoute: {
    key: 'agencyWallSubRoute',
    // path: ({ eventId }) => ``, //NOT USED AS LINK
    //label (intl) => '',
    links: ['eventWall', 'agency', 'agencyWall'],
  },
  buyers: {
    key: 'buyersWallSubRoute',
    path: ({ eventId, companyId }) =>
      `/events/${eventId}/agency/${companyId}/buyers`,
    label: (intl) =>
      intl.formatMessage({
        description: 'Buyers',
        defaultMessage: 'Buyers',
      }),
    links: ['eventWall', 'agency', 'buyers'],
  },
  groups: {
    key: 'groups',
    path: ({ eventId }) => `/events/${eventId}/agency/groups`,
    label: (intl) =>
      intl.formatMessage({
        description: 'Groups',
        defaultMessage: 'Groups',
      }),
    links: ['eventWall'],
  },
  groupDetail: {
    key: 'groupDetail',
    // path: ({ eventId }) => ``, //NOT USED AS LINK
    // label: (intl) => '',
    links: ['eventWall', 'groups'],
  },
  sponsor: {
    key: 'sponsor',
    path: ({ eventId }) => `/events/${eventId}/sponsor`,
    label: (intl) =>
      intl.formatMessage({
        description: 'Sponsor',
        defaultMessage: 'Sponsor',
      }),
    links: ['eventWall'],
  },
  sponsorWall: {
    key: 'sponsorWall',
    path: ({ eventId }) => `/events/${eventId}/sponsor/wall`,
    label: (intl) =>
      intl.formatMessage({
        description: 'Sponsor Wall',
        defaultMessage: 'Sponsor Wall',
      }),
    links: ['eventWall', 'sponsor'],
  },
  sponsorWallSubRoutes: {
    key: 'sponsorWallSubRoutes',
    // path: ({ eventId }) => ``, //NOT USED AS LINK
    // label: (intl) => '',
    links: ['eventWall', 'sponsor', 'sponsorWall'],
  },
  sponsorServices: {
    key: 'sponsorServices',
    path: ({ eventId }) => `/events/${eventId}/sponsor/wall/services`,
    label: (intl) =>
      intl.formatMessage({
        description: 'Services',
        defaultMessage: 'Services',
      }),
    links: ['eventWall', 'sponsor', 'sponsorWall'],
  },
  sponsorServicesSubRoute: {
    key: 'sponsorServicesSubRoute',
    // path: ({ eventId }) => ``, //NOT USED AS LINK
    // label: (intl) => '',
    links: ['eventWall', 'sponsor', 'sponsorWall', 'sponsorServices'],
  },
  participations: {
    key: 'participations',
    path: ({ eventId }) => `/events/${eventId}/participation/clusters/pax`,
    label: (intl) =>
      intl.formatMessage({
        description: 'Participations',
        defaultMessage: 'Participations',
      }),
    links: ['eventWall'],
  },
  participationsWall: {
    key: 'participationsWall',
    path: ({ eventId }) => `/events/${eventId}/participation/clusters/pax/wall`,
    label: (intl) =>
      intl.formatMessage({
        description: 'Participations Wall',
        defaultMessage: 'Participations Wall',
      }),
    links: ['eventWall', 'participations'],
  },
  participationsWallSubRoute: {
    key: 'participationsWallSubRoute',
    // path: ({ eventId }) => ``, //NOT USED AS LINK
    // label: (intl) => '',
    links: ['eventWall', 'participations', 'participationsWall'],
  },
  delegations: {
    key: 'participations',
    path: ({ eventId }) => `/events/${eventId}/participation/delegations`,
    label: (intl) =>
      intl.formatMessage({
        description: 'Delegations',
        defaultMessage: 'Delegations',
      }),
    links: ['eventWall'],
  },
  delegationsWall: {
    key: 'delegationsWall',
    path: ({ eventId }) =>
      `/events/${eventId}/participation/clusters/delegations/wall`,
    label: (intl) =>
      intl.formatMessage({
        description: 'Delegations Wall',
        defaultMessage: 'Delegations Wall',
      }),
    links: ['eventWall', 'delegations'],
  },
  delegationsWallSubRoute: {
    key: 'delegationsWallSubRoute',
    // path: ({ eventId }) => ``, //NOT USED AS LINK
    // label: (intl) => '',
    links: ['eventWall', 'delegations', 'delegationsWall'],
  },
  sponsorList: {
    key: 'sponsorList',
    path: ({ eventId }) => `/events/${eventId}/participation/sponsor-list`,
    label: (intl) =>
      intl.formatMessage({
        description: 'Sponsored Participations',
        defaultMessage: 'Sponsored Participations',
      }),
    links: ['eventWall'],
  },
  sponsorListDetail: {
    key: 'sponsorListDetail',
    // path: ({ eventId }) => ``, //NOT USED AS LINK
    // label: (intl) => '',
    links: ['eventWall', 'sponsorList'],
  },
  sponsorStaff: {
    key: 'sponsorStaff',
    path: ({ eventId }) => `/events/${eventId}/participation/sponsor-staff`,
    label: (intl) =>
      intl.formatMessage({
        description: 'Sponsor Staff',
        defaultMessage: 'Sponsor Staff',
      }),
    links: ['eventWall'],
  },
  sponsorStaffDetail: {
    key: 'sponsorStaffDetail',
    // path: ({ eventId }) => ``, //NOT USED AS LINK
    // label: (intl) => '',
    links: ['eventWall', 'sponsorStaff'],
  },
  allotment: {
    key: 'allotment',
    path: ({ eventId }) => `/events/${eventId}/allotment`,
    label: (intl) =>
      intl.formatMessage({
        description: 'Event Wall',
        defaultMessage: 'Event Wall',
      }),
    links: ['eventWall'],
  },
  allotmentChannelView: {
    key: 'allotmentChannelView',
    path: ({ eventId, hotelEventVentureId }) =>
      `/events/${eventId}/allotment/hotels/${hotelEventVentureId}/channelView`,
    label: (intl) =>
      intl.formatMessage({
        description: 'Channel View',
        defaultMessage: 'Channel View',
      }),
    links: ['eventWall', 'allotment'],
  },
  travelAndTransfer: {
    key: 'travelAndTransfer',
    path: ({ eventId }) => `/events/${eventId}/travel-transfer`,
    label: (intl) =>
      intl.formatMessage({
        description: 'Travel & transfer',
        defaultMessage: 'Travel & transfer',
      }),
    links: ['eventWall'],
  },
  travelList: {
    key: 'travelList',
    path: ({ eventId }) => `/events/${eventId}/travel-list`,
    label: (intl) =>
      intl.formatMessage({
        description: 'Travel List',
        defaultMessage: 'Travel List',
      }),
    links: ['eventWall', 'travelAndTransfer'],
  },
  transferList: {
    key: 'transferList',
    path: ({ eventId }) => `/events/${eventId}/transfer-list`,
    label: (intl) =>
      intl.formatMessage({
        description: 'Transfer List',
        defaultMessage: 'Transfer List',
      }),
    links: ['eventWall', 'travelAndTransfer'],
  },
  billing: {
    key: 'billing',
    path: ({ eventId }) => `/events/${eventId}/billing`,
    label: (intl) =>
      intl.formatMessage({
        description: 'billing table page title',
        defaultMessage: 'Billing',
      }),
    links: ['eventWall'],
  },
  billingWall: {
    key: 'billingWall',
    path: ({ eventId }) => `/events/${eventId}/billing/settings`,
    label: (intl) =>
      intl.formatMessage({
        description: 'billing settings page title',
        defaultMessage: 'Billing Settings',
      }),
    links: ['eventWall', 'billing'],
  },
  billingWallSubPage: {
    key: 'billingWallSubPage',
    // path: ({ eventId }) => ``, //NOT USED AS LINK
    // label: (intl) => '',
    links: ['eventWall', 'billing', 'billingWall'],
  },
  billingFlowTypes: {
    key: 'billingFlowTypes',
    path: ({ eventId }) => `/events/${eventId}/billing/settings/flow-types`,
    label: (intl) =>
      intl.formatMessage({
        description: 'flow types page title',
        defaultMessage: 'Flow Types',
      }),
    links: ['eventWall', 'billing', 'billingWall'],
  },
  billingFlowType: {
    key: 'billingFlowType',
    path: ({ eventId, flowTypeId }) =>
      `/events/${eventId}/billing/settings/flow-types/${flowTypeId}`,
    label: (intl) =>
      intl.formatMessage({
        description: 'flow type page title',
        defaultMessage: 'Flow',
      }),
    links: ['eventWall', 'billing', 'billingWall', 'billingFlowTypes'],
  },
  billingFlowDetail: {
    key: 'billingFlowType',
    // path: ({ eventId, flowTypeId, flowId }) =>
    //   `/events/${eventId}/billing/settings/flow-types/${flowTypeId}/${flowId}`,
    // label: (intl) =>
    //   intl.formatMessage({
    //     description: 'flow detail page title',
    //     defaultMessage: 'Flow Detail',
    //   }),
    links: [
      'eventWall',
      'billing',
      'billingWall',
      'billingFlowTypes',
      'billingFlowType',
    ],
  },
  customizedOrder: {
    key: 'customizedOrder',
    path: ({ eventId }) => `/events/${eventId}/customized-orders`,
    label: (intl) =>
      intl.formatMessage({
        description: 'Customized Order table page title',
        defaultMessage: 'Customized Order',
      }),
    links: ['eventWall'],
  },
  addPayment: {
    key: 'addPayment',
    path: ({ eventId }) => `/events/${eventId}/billing/add-payment`,
    label: (intl) =>
      intl.formatMessage({
        description: 'add payment page title',
        defaultMessage: 'add payment',
      }),
    links: ['eventWall', 'customizedOrder'],
  },
};

export default links;
