import { aws } from '@aim/common';
import PromisePool from '@supercharge/promise-pool';
import { chunk } from 'lodash';

// TODO - add function tu chunck mutations if items length is too big
const massiveMutation = async ({
  query,
  queryName,
  items,
  queryInputType,
  chunkSize = 1000,
  concurrencySize = 1,
}) => {
  if (!items.length) {
    return Promise.resolve({});
  }
  const chunkArray = chunk(items, chunkSize);
  const { results } = await PromisePool.for(chunkArray || [])
    .withConcurrency(concurrencySize)
    .handleError(async (error) => {
      console.error('💥 ERROR: ', error);
      // throwing errors will stop PromisePool and you must catch them yourself
      throw error;
    })
    .process(async (chunk, indexChunkArray) => {
      const { queries, variables, mutationInputs } = chunk.reduce(
        (prev, curr, index) => {
          const nextIndex = index + indexChunkArray * chunkSize;
          const innerQuery = Array.isArray(query) ? query[nextIndex] : query;
          const innerQueryInputType = Array.isArray(queryInputType)
            ? queryInputType[nextIndex]
            : queryInputType;
          const { variables, queries, mutationInputs } = prev;

          const inputName = `i${nextIndex}`;
          const mutationName = `m${nextIndex}`;

          const newVariables = {
            ...variables,
            [inputName]: curr,
          };
          const newQueries = [
            ...queries,
            `${mutationName}: ${innerQuery.replace('$input', `$${inputName}`)}`,
          ];
          const newMutationInputs = [
            ...mutationInputs,
            `
        $${inputName}: ${innerQueryInputType}!`,
          ];

          return {
            variables: newVariables,
            queries: newQueries,
            mutationInputs: newMutationInputs,
          };
        },
        { variables: {}, queries: [], mutationInputs: [] }
      );

      const nextQuery = `mutation ${queryName} (${mutationInputs}) { ${queries.join(
        ''
      )} }`;

      return new Promise((resolve, reject) =>
        aws.API.graphql({
          query: nextQuery,
          variables,
        })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            console.error(`Error executing massive query: ${queryName}`, error);
            reject(error);
          })
      );
    });
  return results.reduce((prev, curr) => ({ ...prev, ...curr }), {});
};

const massiveQuery = async ({
  query,
  queryName,
  items,
  chunkSize = 1000,
  concurrencySize = 1,
}) => {
  if (!items.length) {
    return Promise.resolve({});
  }

  const chunkArray = chunk(items, chunkSize);
  const { results } = await PromisePool.for(chunkArray || [])
    .withConcurrency(concurrencySize)
    .handleError(async (error) => {
      console.error('💥 ERROR: ', error);
      // throwing errors will stop PromisePool and you must catch them yourself
      throw error;
    })
    .process(async (chunk, indexChunkArray) => {
      const queries = chunk.map((item, index) => {
        const nextIndex = index + indexChunkArray * chunkSize;
        const innerQuery = Array.isArray(query) ? query[nextIndex] : query;
        // const inputName = `i${index}`;
        const queryAlias = `q${nextIndex}`;
        if (!item.id) return '';
        const nextQuery = `${queryAlias}: ${innerQuery.replace(
          '$id',
          `"${item.id}"`
        )}`;
        return nextQuery;
      });

      const nextMassiveQuery = `query ${queryName} { ${queries.join(' ')} }`;

      return new Promise((resolve, reject) =>
        aws.API.graphql({
          query: nextMassiveQuery,
        })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            console.error(`Error executing massive query: ${queryName}`, error);
            reject(error);
          })
      );
    });
  return results.reduce((prev, curr) => ({ ...prev, ...curr }), {});
};

export default { massiveMutation, massiveQuery };
