import * as customQueries from '../customQueries';

export const init = (API) => ({
  createNewUserInDb: async (input) => {
    const { data } = await API.graphql({
      query: customQueries.createUser,
      variables: { input },
    });
    return data.createUser;
  },
  createNewUserInCognito: async ({
    username,
    email,
    userType = 'customer',
    eventId,
  }) => {
    const result = await API.post(
      'aimlambdaproxy',
      '/admin-api/adminCreateUser',
      {
        body: {
          username,
          attributes: [
            {
              Name: 'custom:type',
              Value: userType || 'customer',
            },
            ...(eventId
              ? [
                  {
                    Name: 'custom:eventId',
                    Value: eventId,
                  },
                ]
              : []),
            {
              Name: 'email',
              Value: email?.trim()?.toLowerCase(),
            },
            {
              Name: 'email_verified',
              Value: 'true',
            },
          ],
        },
      }
    );

    await API.post('aimlambdaproxy', '/admin-api/addUserToGroup', {
      body: {
        username,
        groupname: userType,
      },
    });

    return result.User;
  },
  resendTemporaryPassword: async ({ username }) => {
    const result = await API.post(
      'aimlambdaproxy',
      '/admin-api/adminCreateUser',
      {
        body: {
          username,
          messageAction: 'RESEND',
          attributes: [],
        },
      }
    );

    return result.User;
  },
  getUserByMail: async (email) => {
    const res = await API.graphql({
      query: customQueries.getUsersByMail,
      variables: { email },
    });
    // console.log(res.data.listUsers.items);
    if (res.data.listUsers.items.length > 0) return res.data.listUsers.items[0];
    else return undefined;
  },
  updateUser: async (user) => {
    const { data } = await API.graphql({
      query: customQueries.updateUser,
      variables: { input: user },
    });
    return data.updateUser;
  },
});
