import { BehaviorSubject } from 'rxjs';
import constants from './constants';

const INIT_TABLE_OBJ = {};
const INIT_OPENSEARCH_OBJ = { filter: [], must_not: [] };
const INIT_FILTER_FORMSTATE_OBJ = { filters: [] };
const filters = {
  participations: new BehaviorSubject(INIT_OPENSEARCH_OBJ),
  aifaAccreditationMaterial: new BehaviorSubject(INIT_OPENSEARCH_OBJ),
  asAdditionalServices: new BehaviorSubject(INIT_OPENSEARCH_OBJ),
  abstract: new BehaviorSubject(INIT_OPENSEARCH_OBJ),
  tsTravelTransfer: new BehaviorSubject(INIT_OPENSEARCH_OBJ),
  generic: new BehaviorSubject(INIT_OPENSEARCH_OBJ),
  participationsFormState: new BehaviorSubject(INIT_FILTER_FORMSTATE_OBJ),
  aifaAccreditationMaterialFormState: new BehaviorSubject(
    INIT_FILTER_FORMSTATE_OBJ
  ),
  asAdditionalServicesFormState: new BehaviorSubject(INIT_FILTER_FORMSTATE_OBJ),
  tsTravelTransferFormState: new BehaviorSubject(INIT_FILTER_FORMSTATE_OBJ),
  abstractFormState: new BehaviorSubject(INIT_FILTER_FORMSTATE_OBJ),
  genericFormState: new BehaviorSubject(INIT_FILTER_FORMSTATE_OBJ),
};

const advancedFilterState = {
  set: ({ name, filter, formState }) => {
    filters[`${name}`].next(filter);
    filters[`${name}FormState`].next(formState);
  },
  get: (name) => {
    return filters[`${name}`].value;
  },
  reset: (name) => {
    if (name) {
      filters[`${name}`].next(INIT_OPENSEARCH_OBJ);
      filters[`${name}FormState`].next(INIT_FILTER_FORMSTATE_OBJ);
      return;
    }

    // TOLTO OBJECT KEYS perchè dentro filters ci sono sia form state che filter non potevamo ciclare
    filters.participations.next(INIT_OPENSEARCH_OBJ);
    filters.aifaAccreditationMaterial.next(INIT_OPENSEARCH_OBJ);
    filters.asAdditionalServices.next(INIT_OPENSEARCH_OBJ);
    filters.abstract.next(INIT_OPENSEARCH_OBJ);
    filters.tsTravelTransfer.next(INIT_OPENSEARCH_OBJ);
    filters.participationsFormState.next(INIT_FILTER_FORMSTATE_OBJ);
    filters.aifaAccreditationMaterialFormState.next(INIT_FILTER_FORMSTATE_OBJ);
    filters.asAdditionalServicesFormState.next(INIT_FILTER_FORMSTATE_OBJ);
    filters.tsTravelTransferFormState.next(INIT_FILTER_FORMSTATE_OBJ);
    filters.abstractFormState.next(INIT_FILTER_FORMSTATE_OBJ);
  },
  isEmpty: () => {
    if (
      filters.participations.getValue() !== INIT_OPENSEARCH_OBJ ||
      filters.aifaAccreditationMaterial.getValue() !== INIT_OPENSEARCH_OBJ ||
      filters.asAdditionalServices.getValue() !== INIT_OPENSEARCH_OBJ ||
      filters.abstract.getValue() !== INIT_OPENSEARCH_OBJ ||
      filters.tsTravelTransfer.getValue() !== INIT_OPENSEARCH_OBJ
    ) {
      return false;
    } else {
      return true;
    }
  },
};

const state = {
  isSidebarOpen: new BehaviorSubject(false),
  selectedMenuItem: new BehaviorSubject(),
  isLoader: new BehaviorSubject(false),
  loaderValue: new BehaviorSubject(0),
  isConfirmExitEnabled: new BehaviorSubject(false),
  user: new BehaviorSubject(),
  eventInfo: new BehaviorSubject({ timezone: 'Europe/Rome' }),
  locale: new BehaviorSubject('en'),
  isI18n: new BehaviorSubject(false),
  zendeskData: new BehaviorSubject(),
  loungeUnreadMessages: new BehaviorSubject(0),
  tableCache: new BehaviorSubject(INIT_TABLE_OBJ),
  participationsDigestsTableCache: new BehaviorSubject([]),
  advancedFilterState,
  template: new BehaviorSubject(constants.Templates.BACKEND),
  eventConfiguration: new BehaviorSubject({
    background: '#FFFFFF',
    primaryColor: '#FFD400',
    primaryButtonVariant: 'contained',
    primaryButtonBorderRadius: '5px',
    secondaryColor: '#84819A',
    secondaryButtonVariant: 'contained',
    secondaryButtonBorderRadius: '5px',
    font: 'Roboto',
    eventGenericMenuItems: {
      items: [],
    },
  }),
  purchaseData: new BehaviorSubject(),
  linksHistory: new BehaviorSubject([]),
  areHeaderMenuVoicesShown: new BehaviorSubject(true),
  mainContainerSize: new BehaviorSubject({
    width: 0,
    height: 0,
  }),
  mainContainerZoom: new BehaviorSubject(1),
  cart: new BehaviorSubject([]),
  cartGateway: new BehaviorSubject(),
};

export default {
  ...state,
  getCurrentParticipation: () =>
    state.user.getValue()?.userAndParticipation?.participation,
};
