import constants from './constants';
import appState from './appState';
import { aws } from '@aim/common';
import { format } from 'date-fns';

import { customAlphabet } from 'nanoid';

const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const nanoid = customAlphabet(alphabet, 6);
import { isAfter, isSameDay, startOfToday, parseISO } from 'date-fns';
import XLSX from 'xlsx';
import differenceInHours from 'date-fns/differenceInHours';
import isWithinInterval from 'date-fns/isWithinInterval';
import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
import {
  getEventInfo,
  incrementEventSequences,
  getOrCreateEventSequences,
} from './GqlHelper';
import pdfHelper from './pdfHelper';
import { endOfDay } from 'date-fns';

const capitalize = (string) => {
  return string?.length
    ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
    : '';
};

const parseFile = (_file) => {
  const originalName = _file?.name || new Date().getTime().toString();

  const matchExtension = originalName.match(/\.[0-9a-z]+$/i);
  const extension = matchExtension ? matchExtension[0] : '';

  const fileSize = _file?.size;

  const input = {
    originalName: originalName,
    extension: extension,
    size: Number(fileSize) || 0,
  };

  const file = _file;
  file.originalName = input.originalName;
  file.extension = input.extension;
  return {
    parsed: input,
    file: file,
  };
};

function blobToFile(theBlob, fileName) {
  return new File([theBlob], fileName, {
    lastModified: new Date().getTime(),
    type: theBlob.type,
  });
}

const getHeaderNames = (header) => {
  return header.map((field) => field.name);
};

const objectsToExcel = ({
  filename,
  header, //{name, columnType}
  keys,
  objects,
  sheets = [{ title: filename, header, keys }],
  returnBlob = false,
  adjustColumnWidth = false,
  forceHeaderBold = false,
}) => {
  return new Promise((resolve) => {
    const workbook = new ExcelJS.Workbook();

    sheets.map((sheet) => {
      //assert errori
      if (!sheet?.header?.length) throw new Error('invalid headers');
      const isHeaderOfString = typeof sheet?.header?.[0] === 'string';
      const data = [];
      const headers = isHeaderOfString ? sheet?.header : getHeaderNames(header);
      objects.forEach((obj) => {
        const row = [];
        sheet?.keys.forEach((k) => {
          const val = obj[k];
          let nextVal = val;
          if (val === '-') nextVal = '';
          row.push(nextVal);
        });
        data.push(row);
      });
      const worksheet = workbook.addWorksheet(
        sheet.title.replaceAll('[', '').replaceAll(']', '')
      );
      let optionsSheet;
      const optionsSheetMap = [];

      if (
        sheet.header.find(
          (h) => !!h.options?.length || h.controlType === 'checkbox'
        )
      ) {
        optionsSheet = workbook.addWorksheet('options', { state: 'hidden' });
        optionsSheet.columns = sheet.header
          .filter((h) => !!h.options?.length || h.controlType === 'checkbox')
          .map((hd, idx) => {
            optionsSheetMap.push({
              header: hd.name,
              letter: String.fromCharCode(97 + idx).toUpperCase(), // Don't ask... https://stackoverflow.com/questions/22624379/how-to-convert-letters-to-numbers-with-javascript
            });
            return {
              header: hd.name,
              key: hd.name,
            };
          });
      }

      sheet?.header?.map((field, index) => {
        // Populate newly created options sheet with related values
        if (!!optionsSheet && !!field.options?.length) {
          optionsSheet.getColumn(field.name).values = [
            field.name,
            ...field.options.map((o) => o.value),
          ];
        } else if (!!optionsSheet && field?.controlType === 'checkbox') {
          optionsSheet.getColumn(field.name).values = [field.name, true, false];
        }
        //if header is a field check if there are options
        let options = [];
        if (
          constants.FieldControlTypes.find(
            (fieldType) =>
              fieldType.withOptions && fieldType.id === field?.controlType
          )
        ) {
          options = field.options;
        } else if (field?.controlType === 'checkbox') {
          options = [true, false];
        }

        const currentColLetter = optionsSheetMap.find(
          (option) => option.header === field.name
        )?.letter;

        if (options.length) {
          worksheet.dataValidations.add(
            `${worksheet.getRow(2).getCell(index + 1).address}:${
              worksheet.getRow(9999).getCell(index + 1).address
            }`,
            {
              type: 'list',
              allowBlank: true,
              formulae: [
                `options!$${currentColLetter}$${2}:$${currentColLetter}$${
                  options.length + 1
                }`,
              ],
              showErrorMessage: true,
              errorStyle: 'error',
              errorTitle: 'Validation Error',
              error: 'The value must one of the list',
            }
          );
        }
      });
      worksheet.columns = headers.map((headerName) => ({
        header: headerName,
        key: headerName,
        style: {
          numFmt:
            header && header.find((h) => h.name === headerName)?.isCurrency
              ? '###0.00 [$€-410];[RED]-###0.00 [$€-410]' //'#.##0 €;[Red]-#.##0 €'
              : undefined,
        },
      }));
      data.map((row) => worksheet.addRow(row));

      if (adjustColumnWidth) {
        worksheet.columns.forEach((column) => {
          const lengths = column.values.map((v) => v.toString().length + 5);
          const maxLength = Math.max(
            ...lengths.filter((v) => typeof v === 'number')
          );
          column.width = maxLength;
        });
      }

      if (forceHeaderBold) {
        const header = worksheet.getRow(1);
        header.eachCell(function (cell) {
          cell.font = {
            bold: true,
            size: 12,
          };
        });
      }
      // worksheet.addConditionalFormatting({
      //   ref: 'G1:G100',
      //   rules: [
      //     {
      //       type: 'expression',
      //       formulae: [
      //         'AND(COUNTIF(Options!$B$1:$D$1,INDIRECT(ADDRESS(ROW(),COLUMN())))=0, INDIRECT(ADDRESS(ROW(),COLUMN()))<>"")',
      //       ],
      //       style: {
      //         fill: {
      //           type: 'pattern',
      //           pattern: 'solid',
      //           bgColor: { argb: 'FFfdcfcf' },
      //         },
      //       },
      //     },
      //   ],
      // });
    });

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      if (returnBlob) {
        resolve(blob);
      } else {
        FileSaver.saveAs(blob, filename);
        resolve();
      }
    });
  });
};

const excelDateNumberToDate = (excelValue) => {
  // Imposta la data di partenza di Excel
  if (excelValue < 3000) {
    throw new Error('Invalid excel date value');
  }
  var dataExcel = parseISO('1900-01-01T00:00:00.000Z');

  // Aggiunge il numero di secondi dal 1900 di excel meno 1 perché Excel conta il 1900 come anno bisestile
  var dataConvertita = new Date(dataExcel.setDate(excelValue - 1));

  return dataConvertita;
};

const ExcelToObjects = (header, file, sortByHeader = true) => {
  return new Promise((resolve, reject) => {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onerror = reject;

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, {
        header: 1,
        blankrows: false,
        UTC: true,
        // dateNF: 'dd/mm/yyyy',
      });
      if (!sortByHeader) header = data[0];
      data.shift();

      const jsData = [];
      data.forEach((d) => {
        let i = 0;
        const item = {};
        header.forEach((h) => {
          item[h] = d[i];
          i++;
        });
        jsData.push(item);
      });

      resolve(jsData);
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  });
};

const ExcelToArrays = (file) =>
  new Promise((resolve, reject) => {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onerror = reject;

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const arrays = XLSX.utils.sheet_to_json(ws, {
        header: 1,
        blankrows: false,
      });
      resolve(arrays);
    };

    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  });

// /**
//  * Function to handle difference between Clusters and their Profile
//  * @param {!String} profileId - The Id of the Profile selected
//  * @returns An array of contexts
//  */
// se vengono passati tutti e 3 da sopra poi sceglie il secondo
const contextsOfUsePerCluster = ({
  cluster,
  clusterId,
  profile,
  profileId,
}) => ({
  [cluster]: [
    'pax',
    ...(clusterId ? [`${cluster}|${clusterId}`] : cluster ? [cluster] : []),
  ],
  [`${cluster}Profile`]: [
    'pax',
    ...(clusterId ? [`${cluster}|${clusterId}`] : cluster ? [cluster] : []),
    ...(profileId ? [`${profile}|${profileId}`] : profile ? [profile] : []),
  ],
  sponsor: ['sponsor'],
});

// profile only with profileId
//['pax', 'delegations'] // not in UI (hidden)
//['pax', 'delegations|123']
//['pax', 'profile|123']
//['pax', 'delegations', 'profile|123'] // not in UI (hidden)
//['pax', 'delegations|123', 'profile|123'] // edit delegate with profile union on field load
const getContextsOfUsePerCluster = ({
  cluster,
  clusterId,
  profileId = '',
  excludePaxAnagraphicFields = false,
}) => {
  const contextSelector = `${cluster}${profileId ? 'Profile' : ''}`;
  return excludePaxAnagraphicFields
    ? contextsOfUsePerCluster({
        cluster,
        clusterId,
        profile: profileId ? 'profile' : null,
        profileId,
      })[contextSelector].filter((context) => context != 'pax')
    : contextsOfUsePerCluster({
        cluster,
        clusterId,
        profile: profileId ? 'profile' : null,
        profileId,
      })[contextSelector];
};

const percentageCalc = (p, v) => {
  return Number(p) + percentage(v, p);
};

const percentage = (v, p) => {
  const perc = (Number(p) * Number(v)) / 100;
  return roundTo2Decimal(perc);
};

const vatCalc = (p, v) => {
  const taxedPrice = percentageCalc(safeNum(p), safeNum(v));
  return roundTo2Decimal(taxedPrice);
};

const amountOfVat = (p, v) => {
  return (Number(p) * Number(v)) / 100;
};

const amountOfVatFromGrossPrice = (p, v) => {
  return Number(p) - (Number(p) * 100) / (Number(v) + 100);
};

const getFinalVat = ({
  participationType,
  vatRate = 0, // il rate DEVE arrivare da fuori SEMPRE già decodato (es 22)
  vatId,
  invoiceToPublicAuthority,
  invoiceTo,
  defaultVat, // arriva da DB quindi prima di utilizzarlo va decodato per mantenere la stessa forma del vatRate
  country,
  vatCode,
  isVatEvent,
}) => {
  const finalVat = {};
  if (isVatEvent === 'true' || invoiceToPublicAuthority === 'true') {
    finalVat.vatRate = 0;
    finalVat.isForced = true;
  } else if (participationType === constants.EventTypes.VIRTUAL.id) {
    const countryLabelLower = (country?.label || country || '')
      ?.toLowerCase?.()
      ?.trim?.();
    if (
      (invoiceTo === constants.InvoiceToType.INDIVIDUAL.id && !vatCode) ||
      countryLabelLower === 'italy' ||
      countryLabelLower === 'italia'
    ) {
      finalVat.vatRate = decodeDbNumber(defaultVat.amount);
      finalVat.vatId = defaultVat?.id;
      finalVat.isForced = true;
    } else {
      finalVat.vatRate = 0;
      finalVat.isForced = true;
    }
  } else {
    finalVat.vatRate = vatRate;
    finalVat.vatId = vatId;
    finalVat.isForced = false;
  }
  return finalVat;
};

const vatOptions = ({
  participationType,
  invoiceToPublicAuthority,
  invoiceTo,
  country,
  vatCode,
  defaultVat = { vatRate: 2200 },
  isVatEvent,
  vatRate1,
  vat1Id,
  vatRate2,
  vat2Id,
}) => {
  const finalVat1 = getFinalVat({
    participationType,
    vatRate: vatRate1,
    vatId: vat1Id,
    invoiceToPublicAuthority,
    invoiceTo,
    defaultVat,
    country,
    vatCode,
    isVatEvent,
  });

  let finalVat2;
  if (vatRate2 !== undefined) {
    finalVat2 = getFinalVat({
      participationType,
      vatRate: vatRate2,
      vatId: vat2Id,
      invoiceToPublicAuthority,
      invoiceTo,
      defaultVat,
      country,
      vatCode,
      isVatEvent,
    });
  }

  return {
    vatRate1: finalVat1.vatRate,
    vat1Id: finalVat1.vatId,
    isForced: finalVat1.isForced,
    vatRate2: finalVat2 ? finalVat2.vatRate : undefined,
    vat2Id: finalVat2 ? finalVat2.vatId : undefined,
  };
};

const roundTo2Decimal = (number) =>
  Math.round((number + Number.EPSILON) * 100) / 100;

const safeNum = (value) => (isNaN(Number(value)) ? 0 : Number(value));

const encodeDbNumber = (value) => safeNum((safeNum(value) * 100).toFixed(2));

const decodeDbNumber = (value) => safeNum(value) / 100;

const formatToFractionDigits = (numberToConvert) =>
  numberToConvert
    ? numberToConvert.toLocaleString('it-IT', {
        minimumFractionDigits: 2,
      })
    : 0;

const priceWithExtraCostCalc = ({
  netPrice,
  vatRate,
  noVat,
  extraPriceDisabled,
  extraPriceRate,
  extraPriceDate,
}) => {
  const extraNetPrice = roundTo2Decimal(
    percentageCalc(netPrice, extraPriceDisabled ? 0 : extraPriceRate)
  );
  const newVatRate = noVat ? 0 : vatRate;

  return {
    applyExtraPrice: extraPriceDate
      ? isAfter(startOfToday(), new Date(extraPriceDate)) ||
        isSameDay(startOfToday(), new Date(extraPriceDate))
      : false,
    vatRate: newVatRate,
    netPrice,
    extraNetPrice,
    vatPrice: vatCalc(netPrice, newVatRate),
    extraVatPrice: vatCalc(extraNetPrice, newVatRate),
  };
};

const formatNumber = (number) =>
  new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(
    number != 0 ? number : 0
  );

const isValidPurchase = (purchase) =>
  (purchase?.payment?.paymentType === constants.PaymentTypes.CreditCard &&
    (purchase?.payment?.paymentIdMonetaOnLine ||
      purchase?.payment?.paymentIdIngenico ||
      purchase?.payment?.paymentIdGpWebpay) &&
    !purchase?.payment?.paymentError) ||
  purchase?.payment?.paymentType === constants.PaymentTypes.BankTransfer;

const isValidPayment = (payment) => {
  // is PENDING or DONE always valid
  // is CANCELLED
  // db statuses lowercase

  if (!payment) {
    return false;
  }
  return (
    // !payment.isDeleted &&
    // ((payment?.paymentType === constants.PaymentTypes.CreditCard &&
    //   (payment?.paymentIdMonetaOnLine ||
    //     payment?.paymentIdIngenico ||
    //     payment?.paymentIdGpWebpay) &&
    //   !payment?.paymentError) ||
    //   payment?.paymentType === constants.PaymentTypes.BankTransfer ||
    //   payment?.paymentType === constants.PaymentTypes.Free)
    !payment.isDeleted && !payment?.paymentError
  );
};

const isValidPaymentForAgency = (payment) => {
  if (!payment) {
    return false;
  }
  return (
    !payment.isDeleted &&
    ((payment?.paymentType === constants.PaymentTypes.CreditCard &&
      (payment?.paymentIdMonetaOnLine ||
        payment?.paymentIdIngenico ||
        payment?.paymentIdGpWebpay) &&
      !payment?.paymentError) ||
      payment?.paymentType === constants.PaymentTypes.BankTransfer ||
      payment?.paymentType === constants.PaymentTypes.Free)
  );
};

const isPaymentExpired = (payment) =>
  !(
    payment?.paymentIdMonetaOnLine ||
    payment?.paymentIdIngenico ||
    payment?.paymentIdGpWebpay
  ) && differenceInHours(new Date(), payment.createdAt) > 4;

const getParticipationPrice = (participation, date, vat) => {
  const bracket = participation.profile?.profileFeeBrackets.items.find(
    (profileFeeFracket) => {
      const sameId =
        profileFeeFracket.feeBracket.feeDateRange?.id ===
        participation.feeDateRange?.id;
      // fast return if already different
      if (!sameId) return false;

      // questo controllo è per trovare il feebracket in base allo scaglione
      const baseDate = date ? new Date(date) : new Date();
      const isWithinBracketInterval = isWithinInterval(baseDate, {
        start: new Date(profileFeeFracket.feeBracket.feeBracketModel.start),
        end: endOfDay(
          new Date(profileFeeFracket.feeBracket.feeBracketModel.end)
        ),
      });
      return isWithinBracketInterval;
    }
  );

  if (bracket) {
    if (
      participation.type === constants.EventTypes.VIRTUAL.id &&
      bracket.priceOnAir &&
      bracket.priceOnAir > 0
    ) {
      const netPrice = decodeDbNumber(bracket.priceOnAir);
      const vatRate = decodeDbNumber(vat?.amount);
      return {
        profileFeeBracketId: bracket.id,
        netPrice,
        price: vatCalc(netPrice, decodeDbNumber(vat?.amount)),
        vatRate,
        vatId: vat?.id,
        vat,
        feeDateRange: bracket.feeBracket.feeDateRange.id,
        feeDateRangeLabel: bracket.feeBracket.feeDateRange.label,
        feeDateRangeStart: bracket.feeBracket.feeDateRange.start,
        feeDateRangeEnd: bracket.feeBracket.feeDateRange.end,
      };
    }
    if (
      participation.type === constants.EventTypes.PHYSICAL.id &&
      bracket.priceOnSite &&
      bracket.priceOnSite > 0
    ) {
      const netPrice = decodeDbNumber(bracket.priceOnSite);
      const vatRate = decodeDbNumber(vat?.amount);
      return {
        profileFeeBracketId: bracket.id,
        netPrice,
        price: vatCalc(netPrice, decodeDbNumber(vat?.amount)),
        vatRate,
        vatId: vat?.id,
        vat,
        feeDateRange: bracket.feeBracket.feeDateRange.id,
        feeDateRangeLabel: bracket.feeBracket.feeDateRange.label,
        feeDateRangeStart: bracket.feeBracket.feeDateRange.start,
        feeDateRangeEnd: bracket.feeBracket.feeDateRange.end,
      };
    }
  }

  return { profileFeeBracketId: null, price: 0 };
};

const checkEventAppState = async (url) => {
  try {
    const oldEventId = appState.eventInfo.getValue()?.id;
    if (url?.search('/events/') !== -1) {
      const positionEventId = url.search('/events/') + 8;
      const eventId = url.substr(positionEventId, 36);
      if (!oldEventId || oldEventId !== eventId) {
        const res = await getEventInfo(eventId);
        if (res) {
          appState.eventInfo.next(res);
          //settati singolarmente per mantenere il default se mancano
          appState.eventConfiguration.next({
            background: res?.configuration?.background || '#FFFFFF',
            primaryColor: res?.configuration?.primaryColor || '#FFD400',
            primaryButtonVariant:
              res?.configuration?.primaryButtonVariant || 'contained',
            primaryButtonBorderRadius:
              res?.configuration?.primaryButtonBorderRadius || '5px',
            secondaryColor: res?.configuration?.secondaryColor || '#84819A',
            secondaryButtonVariant:
              res?.configuration?.secondaryButtonVariant || 'contained',
            secondaryButtonBorderRadius:
              res?.configuration?.secondaryButtonBorderRadius || '5px',
            font: res?.configuration?.font || 'Roboto',
          });
        }
      }
    } else if (oldEventId) {
      const eventData = appState.eventInfo.getValue();
      appState.eventInfo.next({ ...eventData, timezone: 'Europe/Rome' });
    }
  } catch (err) {
    console.error('error', err);
  }
};

const getNextSequenceValue = async ({
  eventCode,
  // eventId,
  sequenceName,
  valueOfIncrement = 1,
}) => {
  const nextSequenceValue = await incrementEventSequences(
    {
      id: eventCode,
      [sequenceName]: valueOfIncrement,
    },
    sequenceName
  );
  return nextSequenceValue[sequenceName];
};

const printSourceMappedStackTrace = async (stackTrace, error) => {
  if (
    // to prevent message for develop errors
    !window.location.href.startsWith('http://localhost')
  ) {
    const { awsUser, userAndParticipation, ...usr } =
      appState.user.getValue() || {};

    const { language, languages, userAgent, platform } = window.navigator;

    await aws.standardAPI.post('aimlambdaproxy', '/admin/uncaught-error', {
      body: {
        userInfo: {
          participation: userAndParticipation?.participation,
          user: Object.keys(usr).length ? usr : undefined,
          groups: awsUser?.groups,
        },
        pageUrl: window.location.href,
        navigator: { language, languages, userAgent, platform },
        stackTrace,
        error,
      },
    });
  }
};

const isValidEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const getFieldsByContextsQuery = /* GraphQL */ `
  query GetFieldsByContexts(
    $id: ID!
    $filter: ModelFieldContextFilterInput
  ) {
    getEvent(id: $id) {
      id
      standardFields(filter: { isDeleted: { ne: true } }) {
        items {
          controlType
          id
          key
          isDeleted
          label
          options
          placeholder
          contextsOfUse(filter: $filter) {
            items {
              id
              position
              contextName
              isRequired
              isHidden
            }
          }
        }
      }
      services(filter: { serviceName: { eq: "${constants.Services.PARTICIPATION.key}" } }) {
        items {
          serviceName
          id
          customFields(filter: { isDeleted: { ne: false } }) {
            items {
              controlType
              id
              key
              isDeleted
              label
              options
              placeholder
              contextsOfUse(filter: $filter) {
                items {
                  id
                  position
                  contextName
                  isRequired
                  isHidden
                }
              }
            }
          }
        }
      }
    }
  }
`;

const getFieldsByContexts = ({ eventId, contextsOfUse }) =>
  new Promise((resolve, reject) => {
    aws.API.graphql({
      query: getFieldsByContextsQuery,
      variables: {
        id: eventId,
        filter: { or: contextsOfUse.map((c) => ({ contextName: { eq: c } })) },
      },
    })
      .then((response) => {
        const nextResponse = {
          standardFields: response.data.getEvent.standardFields.items,
          customFields:
            response.data.getEvent.services.items[0].customFields.items,
        };
        resolve(nextResponse);
      })
      .catch((e) => {
        console.error('get-event-fields', e);
        reject();
      });
  });

const unionToContextOfUse = (fields, contexts) => {
  const outFields = fields.map((field) => {
    let fieldFirstContextOfUse;
    for (let i = 0; i < contexts.length; i++) {
      fieldFirstContextOfUse = field.contextsOfUse.items.find(
        (cu) => cu.contextName === contexts[i]
      );
      if (fieldFirstContextOfUse) break;
    }
    return {
      ...field,
      options: field.options ? JSON.parse(field.options) : null,
      contextsOfUse: {
        ...fieldFirstContextOfUse,
        isVisible: !fieldFirstContextOfUse.isHidden,
      },
      isReadOnly:
        fieldFirstContextOfUse.contextName !== contexts[contexts.length - 1],
    };
  });
  return outFields;
};

// qui torniamo Art. 7 Reverse Charge o Not Reverse Charge invece che i codici zucchetti in modo che se cambiano non dobbiamo fare deploy
// mantenere questa funzione allineata con quella su aimBilling generateFlowTrigger (si chiama uguale)
const extractZucchettiVatRateCode = ({
  eventTaxRepresentative,
  defaultVat,
  vatCode,
  countryJson,
  participationType,
  vatToCheck,
  intl,
}) => {
  if (!vatToCheck && participationType === constants.EventTypes.PHYSICAL.id)
    return defaultVat.zucchettiCode; // IMPORTANTE: diamo per assunto che gli eventi sponsor sono in Italia e quindi prendono default iva italiana
  if (participationType === constants.EventTypes.PHYSICAL.id) {
    const vat = eventTaxRepresentative?.vats.items.find(
      (v) => v.id === vatToCheck
    );
    if (!vat) {
      throw new Error('VAT not found');
    }
    return vat.zucchettiCode;
  } else if (participationType === constants.EventTypes.VIRTUAL.id) {
    if (!vatCode || (vatCode && countryJson.alpha2 === 'IT')) {
      return defaultVat.zucchettiCode;
    } else if (countryJson.isCee) {
      // zucchetti code ART 7 REVERSE CHARGE
      return intl.formatMessage({
        description: 'ART 7 REVERSE CHARGE',
        defaultMessage: 'Art. 7 Reverse Charge',
      });
    } else {
      //  zucchetti code ART 7 NON REVERSE CHARGE
      return intl.formatMessage({
        description: 'ART 7 NOT REVERSE CHARGE',
        defaultMessage: 'Art. 7 Not Reverse Charge',
      });
    }
  }
};

const loadAnagraphicFieldsWithoutUploads = async (
  eventId,
  contextsOfUse = ['pax']
) => {
  const { standardFields, customFields } = await getFieldsByContexts({
    eventId,
    contextsOfUse,
  });

  return anagraphicFieldsWithoutUploads(
    standardFields,
    customFields,
    contextsOfUse
  );
};

const anagraphicFieldsWithoutUploads = (
  standardFields,
  customFields,
  contextsOfUse = ['pax']
) => {
  const outStandardFields = unionToContextOfUse(
    standardFields.filter((field) => field.contextsOfUse.items.length),
    contextsOfUse
  );
  const outCustomFields = unionToContextOfUse(
    customFields.filter((field) => field.contextsOfUse.items.length),
    contextsOfUse
  );
  return [
    ...outStandardFields.filter((x) => x.controlType !== 'upload'),
    ...outCustomFields.filter((x) => x.controlType !== 'upload'),
  ];
};

const exportExcel = ({
  rawData,
  exportColumns,
  filename,
  header,
  returnBlob = false,
}) => {
  const visibleRows = Object.entries(rawData.filter?.visibleRowsLookup || {})
    .filter(([_, isVisible]) => isVisible)
    .map(([key]) => key)
    .sort((a, b) => {
      const aIndex = rawData?.sorting?.sortedRows.indexOf(a);
      const bIndex = rawData?.sorting?.sortedRows.indexOf(b);
      return aIndex < bIndex ? -1 : 1;
    });

  const isTree = rawData.columns?.all?.find(
    (x) => x.field === '__tree_data_group__'
  );
  const rowIdsForLookup = rawData.selection?.length
    ? rawData.selection
    : !isTree && visibleRows?.length
    ? visibleRows
    : rawData.rows?.ids;

  let columns = rawData.columns?.all;
  if (header?.length) {
    const headerKeys = header.map((col) => col.id);
    columns = rawData.columns?.all.filter((col) => headerKeys.includes(col));
  }

  let headerObjects = columns
    .filter((key) => key !== '__check__' && key !== 'actions')
    .map((key) => rawData.columns?.lookup[key])
    .filter((x) => !x.hide);

  if (exportColumns) {
    headerObjects = exportColumns.map((field) => {
      const headerCellChecked = headerObjects.find((x) => x.field === field);
      return headerCellChecked;
    });
  }
  const keys = headerObjects.map((x) => x.field);
  // const nextHeader = headerObjects.map((x) => x.headerName);
  const nextHeaderObject = headerObjects.map((x) => ({
    ...x,
    name: x.headerName,
  }));

  const objects = rowIdsForLookup
    .map((x) => rawData.rows?.idRowsLookup[x])
    .map((x) =>
      headerObjects.reduce(
        (prev, curr) => {
          const rowNode = rawData.rows?.tree?.[x.id];
          const value =
            curr.valueGetter &&
            curr.valueGetter({
              row: x,
              rowNode,
            })
              ? curr.valueGetter({
                  row: x,
                  rowNode,
                })
              : x[curr.field];
          let finalValue =
            curr.type === 'boolean'
              ? (value || false).toString() // || false is to prevent null values
              : curr.valueFormatter
              ? curr.valueFormatter({ row: x, formattedValue: value, value })
              : value;
          if (curr.field === '__tree_data_group__') {
            finalValue = '    '.repeat(rowNode.depth) + finalValue;
          }
          return { ...prev, [curr.field]: finalValue };
        },

        {}
      )
    );

  return objectsToExcel({
    filename: filename,
    header: nextHeaderObject,
    keys,
    objects,
    returnBlob,
  });
};

const exportDataGridToPdf = ({
  rawData,
  exportColumns,
  fileName,
  tableName,
  includeIndex = false,
}) => {
  const visibleRows = Object.entries(rawData.filter?.visibleRowsLookup || {})
    .filter(([_, isVisible]) => isVisible)
    .map(([key]) => key)
    .sort((a, b) => {
      const aIndex = rawData?.sorting?.sortedRows.indexOf(a);
      const bIndex = rawData?.sorting?.sortedRows.indexOf(b);
      return aIndex < bIndex ? -1 : 1;
    });

  const isTree = rawData.columns?.all?.find(
    (x) => x.field === '__tree_data_group__'
  );
  const rowIdsForLookup = rawData.selection?.length
    ? rawData.selection
    : !isTree && visibleRows?.length
    ? visibleRows
    : rawData.rows?.ids;

  let headerObjects = rawData.columns?.all
    .filter((key) => key !== '__check__' && key !== 'actions')
    .map((key) => rawData.columns?.lookup[key])
    .filter((x) => !x.hide);

  if (exportColumns) {
    headerObjects = exportColumns.map((field) => {
      const headerCellChecked = headerObjects.find((x) => x.field === field);
      return headerCellChecked;
    });
  }

  const header = headerObjects.map((x) => x.headerName);
  const columnStyles = {
    ...headerObjects.map((x) => ({
      cellWidth: x.width / 2,
    })),
  };
  const objects = rowIdsForLookup
    .map((x) => rawData.rows?.idRowsLookup[x])
    .map((x, index) => {
      const nextArray = headerObjects.reduce((prev, curr) => {
        const rowNode = rawData.rows?.tree?.[x.id];
        const value =
          curr.valueGetter &&
          curr.valueGetter({
            row: x,
            rowNode,
          })
            ? curr.valueGetter({
                row: x,
                rowNode,
              })
            : x[curr.field];
        let finalValue =
          curr.type === 'boolean'
            ? (value || false).toString() // || false is to prevent null values
            : curr.valueFormatter
            ? curr.valueFormatter({ row: x, formattedValue: value, value })
            : value;
        if (curr.field === '__tree_data_group__') {
          finalValue = '    '.repeat(rowNode.depth) + finalValue;
        }
        return [...prev, finalValue];
      }, []);
      return includeIndex ? [index + 1, ...nextArray] : nextArray;
      // return nextArray.slice(0, 10);
    });
  const tables = [
    {
      head: [includeIndex ? ['Index', ...header] : header],
      body: objects,
      title: tableName || fileName,
    },
  ];

  pdfHelper.exportTablePdfWithStandardTemplate({
    tables,
    eventData: appState.eventInfo.getValue(),
    tableName: { tableTitle: fileName },
    horizontalPageBreak: true,
    horizontalPageBreakRepeat: includeIndex ? [0, 1] : 0,
    horizontalPageBreakBehaviour: 'immediately',
    columnStyles,
  });
};

// due to issue #1727 booked should not include locked
const getAllotmentBookedCount = (booked, locked) =>
  (booked ?? 0) - (locked ?? 0);

// due to issue #1730 the remaining should be calculated from total availability minus busy rooms
const getAllotmentRemainingCount = (available, busy) =>
  (available ?? 0) - (busy ?? 0);

const sponsorUtilities = {
  getUnitaryPrice: (item) => {
    if (item?.sponsorPackage)
      return safeNum(
        item.sponsorPackage.services.items.reduce(
          (prev, curr) => prev + decodeDbNumber(curr.netPrice) * curr.quantity,
          0
        )
      );
    else return decodeDbNumber(item.unitaryPrice);
  },
  getUnitaryVatAmount: (item, vatRate) => {
    if (item?.sponsorPackage) {
      const vatAmountOfPackage = safeNum(
        item.sponsorPackage.services.items.reduce((prev, curr) => {
          const priceOfPackage = decodeDbNumber(curr.netPrice) * curr.quantity;
          return prev + amountOfVat(priceOfPackage, vatRate);
        }, 0)
      );
      return vatAmountOfPackage;
    } else {
      const vatAmount = amountOfVat(decodeDbNumber(item.unitaryPrice), vatRate);
      return vatAmount;
    }
  },
  getUnitaryCost: (item) => {
    if (item.bannerArea !== null) {
      return decodeDbNumber(item.bannerArea.cost);
    } else if (item.symposium !== null) {
      return decodeDbNumber(item.symposium.cost);
    } else if (item.publicPage !== null) {
      return decodeDbNumber(item.publicPage.publicPageService.cost);
    } else if (item.breakoutRoom !== null) {
      return decodeDbNumber(item.breakoutRoom.breakoutRoomService.cost);
    } else if (item.physicalStandSpace !== null) {
      return decodeDbNumber(item.physicalStandSpace.cost);
    } else if (item.physicalStandSpaceItems !== null) {
      return decodeDbNumber(item.physicalStandSpaceItems?.item?.cost);
    } else if (item.sponsorVirtualStand !== null) {
      return decodeDbNumber(item.sponsorVirtualStand.virtualStand.cost);
    } else if (item.sponsorStaffPurchase !== null) {
      return 0;
    } else if (item.sponsorListPurchase !== null) {
      return 0;
    } else if (item.otherSponsorizationsBookingItem !== null) {
      return decodeDbNumber(item.otherSponsorizationsBookingItem.item.cost);
    } else if (item.symposiumServicesPurchase !== null) {
      return decodeDbNumber(
        item.symposiumServicesPurchase?.symposiumServicesItem?.cost
      );
    } else if (item.sponsorPackage !== null) {
      return decodeDbNumber(
        item.sponsorPackage.services.items.reduce((acc, currentService) => {
          let serviceCost = 0;
          serviceCost += currentService.physicalStand?.cost || 0;
          serviceCost += currentService.publicPageService?.cost || 0;
          serviceCost += currentService.symposium?.cost || 0;
          serviceCost += currentService.symposiumService?.cost || 0;
          serviceCost +=
            currentService.symposiumServicesPurchase?.symposiumServicesItem
              ?.cost || 0;
          serviceCost += currentService.virtualStand?.cost || 0;
          serviceCost += currentService.breakoutRoomService?.cost || 0;
          serviceCost += currentService.bannerArea?.cost || 0;
          serviceCost +=
            currentService.otherSponsorizationsBookingItem?.item?.cost || 0;
          serviceCost += currentService.physicalStandSpaceItem?.item?.cost || 0;
          return acc + serviceCost;
        }, 0) || 0
      );
    } else return 0;
  },
  getUnitaryVatRate: (item) => {
    if (item?.sponsorPackage)
      return safeNum(
        item.sponsorPackage.services.items.reduce(
          (prev, curr) => prev + decodeDbNumber(curr.vatRate),
          0
        )
      );
    else return decodeDbNumber(item.unitaryVatRate) || 0;
  },
  getQuantity: (item) => {
    if (item.physicalStandSpaceItems !== null)
      return (
        Number(item?.physicalStandSpaceItems?.quantity || 1) *
        Number(item?.physicalStandSpaceItems?.amountPerQuantity || 1)
      );
    else if (item.otherSponsorizationsBookingItem !== null)
      return (
        Number(item?.otherSponsorizationsBookingItem?.quantity || 1) *
        Number(item?.otherSponsorizationsBookingItem?.amountPerQuantity || 1)
      );
    else if (item.sponsorStaffPurchase !== null)
      return Number(item?.sponsorStaffPurchase?.quantity || 1);
    else if (item.symposiumServicesPurchase !== null)
      return Number(item?.symposiumServicesPurchase?.quantity || 1);
    else if (item.sponsorListPurchase !== null)
      return Number(item?.sponsorListPurchase?.quantity || 1);
    else if (item?.sponsorPackage)
      return Number(item?.sponsorPackage?.quantity || 1);
    else return 1;
  },
};

const truncateString = (str = '', maxlength) => {
  return str.length > maxlength ? str.slice(0, maxlength - 1) + '…' : str;
};

const generatePaymentId = (eventCode) => `${eventCode}-${nanoid()}`;

const calculateTotalPriceAdditionalServiceWithBrackets = (
  additionalService
) => {
  const priceFound = additionalService.prices?.items?.find((price) => {
    const isWithinBracketInterval = isWithinInterval(new Date(), {
      start: new Date(price.start),
      end: endOfDay(new Date(price.end)),
    });
    return isWithinBracketInterval ? price : undefined;
  });

  console.log(
    'calculateTotalPriceAdditionalServiceWithBrackets priceFound ',
    priceFound
  );

  if (priceFound) {
    const decodedAmount1 = decodeDbNumber(priceFound.netPrice1);
    const decodedAmount2 = decodeDbNumber(priceFound.netPrice2);
    const netPrice = decodedAmount1 + decodedAmount2;
    const vat =
      percentage(decodeDbNumber(priceFound?.vat1?.amount), decodedAmount1) +
      percentage(decodeDbNumber(priceFound?.vat2?.amount), decodedAmount2);
    console.log(
      'calculateTotalPriceAdditionalServiceWithBrackets netPrice ',
      netPrice
    );
    console.log('calculateTotalPriceAdditionalServiceWithBrackets vat ', vat);
    return netPrice + vat;
  }
  return 0;
};

const getAdditionalServiceBracketInfo = (additionalService) => {
  const priceFound = additionalService.prices?.items?.find((price) => {
    const isWithinBracketInterval = isWithinInterval(new Date(), {
      start: new Date(price.start),
      end: endOfDay(new Date(price.end)),
    });
    return isWithinBracketInterval ? price : undefined;
  });
  return {
    vat1: priceFound?.vat1,
    vat2: priceFound?.vat2,
    vat1Id: priceFound?.vat1.id,
    vat2Id: priceFound?.vat2?.id,
    vatRate1: priceFound?.vat1?.amount,
    vatRate2: priceFound?.vat2?.amount || 0,
    netPrice1: priceFound?.netPrice1,
    netPrice2: priceFound?.netPrice2 || 0,
  };
};

const calculateTotalPriceAdditionalService = (additionalService) => {
  const decodedAmount1 = decodeDbNumber(additionalService?.amount1);
  const decodedAmount2 = decodeDbNumber(additionalService?.amount2);
  const netPrice = decodedAmount1 + decodedAmount2;
  const vat =
    percentage(
      decodeDbNumber(
        additionalService.additionaServiceVat1?.amount || additionalService.vat1
      ),
      decodedAmount1
    ) +
    percentage(
      decodeDbNumber(
        additionalService.additionaServiceVat2?.amount || additionalService.vat2
      ),
      decodedAmount2
    );
  return netPrice + vat;
};

const generateTicketLabel = ({
  feeDateRange,
  profile,
  participationMode,
  intl,
}) => {
  const startDate = format(new Date(feeDateRange.start), 'dd/MM/yyyy');
  const endDate = format(new Date(feeDateRange.end), 'dd/MM/yyyy');
  const isStartDateEqualEndDate = startDate === endDate;
  return `${profile.name} - ${feeDateRange.label} - ${Object.values(
    constants.EventTypes
  )
    .find((t) => t.id === participationMode)
    ?.label(intl)} - ${startDate}${
    isStartDateEqualEndDate ? '' : ` - ${endDate}`
  }`;
};

export default {
  objectsToExcel,
  ExcelToObjects,
  ExcelToArrays,
  excelDateNumberToDate,
  contextsOfUsePerCluster,
  getContextsOfUsePerCluster,
  parseFile,
  blobToFile,
  encodeDbNumber,
  decodeDbNumber,
  formatToFractionDigits,
  isValidPurchase,
  isValidPayment,
  isValidPaymentForAgency,
  isPaymentExpired,
  formatNumber,
  safeNum,
  vatCalc,
  amountOfVat,
  amountOfVatFromGrossPrice,
  vatOptions,
  percentage,
  roundTo2Decimal,
  priceWithExtraCostCalc,
  getParticipationPrice,
  checkEventAppState,
  getNextSequenceValue,
  getOrCreateEventSequences,
  isValidEmail,
  capitalize,
  loadAnagraphicFieldsWithoutUploads,
  anagraphicFieldsWithoutUploads,
  exportExcel,
  exportDataGridToPdf,
  printSourceMappedStackTrace,
  getAllotmentBookedCount,
  getAllotmentRemainingCount,
  sponsorUtilities,
  truncateString,
  extractZucchettiVatRateCode,
  generatePaymentId,
  calculateTotalPriceAdditionalService,
  calculateTotalPriceAdditionalServiceWithBrackets,
  getAdditionalServiceBracketInfo,
  generateTicketLabel,
};

export const _tests = {
  getFieldsByContextsQuery,
};
